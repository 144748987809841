import _ from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelected, useSlateStatic } from "slate-react";

import {
  JobRange,
  JobSpeaker,
  SpeakerRangeElement,
} from "@sumit-platforms/types";
import { TimecodePicker, TimerPicker } from "@sumit-platforms/ui-bazar";
import {
  useKeyboardShortcuts,
  useLazyLoad,
} from "@sumit-platforms/ui-bazar/hooks";
import {
  getSecondsFromTimecode,
  getTcOffsetByStartTime,
  getTcString,
  getTimecodeFromSeconds,
} from "@sumit-platforms/ui-bazar/utils";

import { useRecoilValue } from "recoil";
import {
  directionState,
  featureFlagsState,
  isDisabledState,
} from "../../store/states";

import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";
import { UpdateTcOffsetFn } from "../../editor";
import SpeakerNameEditor from "../SpeakerNameEditor/SpeakerNameEditor";

import "./RangeSpeaker.scss";

interface Props {
  element: SpeakerRangeElement;
  updateTcOffset: UpdateTcOffsetFn;
  children?: React.ReactNode;
  attributes?: any;
  allowTimeEdit?: boolean;
  hideTimecode?: boolean;
  handleBlur?: (rangeIndex?: number) => void;
}

const speakersTimesHeight = 60;

const RangeSpeaker: FC<Props> = ({
  element,
  children,
  attributes,
  updateTcOffset,
  allowTimeEdit = true,
  hideTimecode,
  handleBlur,
}) => {
  const editor = useSlateStatic();
  const isElementSelected = useSelected();
  const disabled: boolean = useRecoilValue(isDisabledState);
  const direction = useRecoilValue(directionState);
  const featureFlags = useRecoilValue(featureFlagsState);

  const { t } = useTranslation();
  const [isEditingTime, setIsEditingTime] = useState<boolean | string>(false);
  const [tcOffset] = useState(
    getTcOffsetByStartTime(element.tcOffsets, element.range.st)
  );

  const rangeRef = useRef<HTMLDivElement | null>(null);
  const isElementSelectedPrevValue = useRef(false);

  const { hasIntersected } = useLazyLoad(
    rangeRef,
    !!featureFlags.virtualizeSpeakers
  );

  useEffect(() => {
    if (isElementSelectedPrevValue.current && !isElementSelected) {
      const index = EditorService.getCurrentIndexByElement(editor, element);
      if (_.isNumber(index) && handleBlur) {
        handleBlur(index);
      }
    }

    isElementSelectedPrevValue.current = isElementSelected;
  }, [isElementSelected]);

  const jumpToWord = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent) => {
      let { selectionStart } = EditorService.getCursorPosition(
        e?.target as HTMLDivElement
      );

      if (!_.isNumber(selectionStart)) return;
      const plainWords = EditorService.getPlainWordsFromRange(element.range);
      const editedWords = plainWords.split(" ");

      const lengths = editedWords.map((word) => word.length);

      let clickedWord = -1;
      while (selectionStart > 0) {
        clickedWord++;
        selectionStart = selectionStart - lengths[clickedWord] - 1;
      }
      if (selectionStart === 0) clickedWord++;

      if (element.range.words && element.range.words[clickedWord]) {
        MediaService.setOffset(element.range.words[clickedWord].start_time);
      }
    },
    [element.range]
  );

  const handleSetNewSpeakerName = useCallback(
    (speaker?: JobSpeaker) => {
      if (element.range.speakerName !== speaker?.name) {
        const newRange = {
          ...element.range,
          speakerName: speaker?.name,
        } as JobRange;
        EditorService.updateNodeData({
          data: { range: newRange },
          editor,
          element,
        });
      }
      EditorService.focusByPathOrElement(editor, { element });
    },
    [editor, element]
  );

  const focusElement = useCallback(() => {
    EditorService.focusByPathOrElement(editor, { element });
  }, [editor, element]);

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      jumpToWord(e);
    },
    [jumpToWord]
  );

  const handleTimeChangeApprove = useCallback(
    (time: string) => {
      const st = TimeService.getTimeNumberFromString(time);
      const updatedFirstWord = {
        ...element.range.words[0],
        st,
        start_time: st,
        time_edit: true,
      };
      const newWords = [updatedFirstWord, ...element.range.words.slice(1)];

      const newRange = {
        ...element.range,
        time_edit: true,
        st,
        words: newWords,
      };
      EditorService.updateNodeData({
        data: { range: newRange },
        editor,
        element,
      });
      setIsEditingTime(false);
    },
    [editor, element]
  );

  const handleUpdateTcOffset = useCallback(
    ({ startTime, tcOffset }: { startTime: number; tcOffset: number }) => {
      const rangeIndex = EditorService.getCurrentIndexByElement(
        editor,
        element
      );
      if (typeof rangeIndex !== "number") return;
      updateTcOffset({ editor, rangeIndex, startTime, tcOffset });
    },
    [editor, element, updateTcOffset]
  );

  const handleTimecodeChangeApprove = useCallback(
    (time: string) => {
      const timeInSec = featureFlags?.timecodePicker
        ? getSecondsFromTimecode(time)
        : TimeService.getTimeNumberFromString(time);
      handleUpdateTcOffset({
        startTime: element.range.st,
        tcOffset: timeInSec,
      });
      setIsEditingTime(false);
    },
    [element.range.st, featureFlags?.timecodePicker, handleUpdateTcOffset]
  );

  const handleTimeChangeCancel = useCallback(() => {
    setIsEditingTime(false);
  }, []);

  const onBeforeSpeakersFocus = useCallback(() => {
    editor.deselect();
  }, [editor]);

  const onSpeakersUnmount = useCallback(() => {
    if (featureFlags.virtualizeSpeakers) {
      setTimeout(() => {
        EditorService.updateNodeData({
          data: { focusOnInit: false },
          editor: editor,
          element: element,
        });
      }, 250);
    }
  }, [editor, element, featureFlags.virtualizeSpeakers]);

  useKeyboardShortcuts({
    handlers: {
      PREVENT_CUT: EditorService.preventCut,
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  return (
    <div
      className={classNames("RangeSpeaker", "range", {
        ltr: direction === "ltr",
        rtl: direction === "rtl",
        disabled,
        // error: !_.isEmpty(range.validation?.errors),
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
      ref={rangeRef}
      {...attributes}
    >
      <div
        className={classNames("speakerTimesContainer noSelect", {
          disabled,
          // hide: !isVisible,
          // overlapping:
          //   range.validation?.errors?.overlapping_start_prev ||
          //   range.validation?.errors?.overlapping_start_next ||
          //   range.validation?.errors?.start_after_end,
        })}
      >
        <div
          className="speakerTimesContainerComponents sticky"
          style={{ height: `${speakersTimesHeight}px` }}
        >
          {hasIntersected ? (
            <>
              <div
                className={classNames("speakerContainer noSelect", {
                  disabled,
                })}
                contentEditable={false}
              >
                <div className="rangeSpeaker">
                  <SpeakerNameEditor
                    speakerName={element.range.speakerName}
                    onChange={handleSetNewSpeakerName}
                    placeholder={t("choose_speaker")}
                    focusOnInit={element.focusOnInit}
                    onBeforeSpeakersFocus={onBeforeSpeakersFocus}
                    focusElement={focusElement}
                    onUnmount={onSpeakersUnmount}
                  />
                </div>
              </div>
              <div className="timesContainer" contentEditable={false}>
                {isEditingTime ? (
                  <>
                    {isEditingTime === "time" && (
                      <TimerPicker
                        className={classNames({
                          ltr: direction === "ltr",
                        })}
                        value={element.range.st}
                        handleChange={_.noop}
                        handleBlur={_.noop}
                        handleApprove={handleTimeChangeApprove}
                        handleCancel={handleTimeChangeCancel}
                        step={1}
                        disabled={disabled}
                      />
                    )}
                    {isEditingTime === "timecode" ? (
                      featureFlags?.timecodePicker ? (
                        <TimecodePicker
                          className={classNames({
                            ltr: direction === "ltr",
                          })}
                          value={getTimecodeFromSeconds(element.range.st, {
                            tcOffsets: element.tcOffsets,
                          })}
                          handleChange={_.noop}
                          handleBlur={_.noop}
                          handleApprove={handleTimecodeChangeApprove}
                          handleCancel={handleTimeChangeCancel}
                          disabled={disabled}
                        />
                      ) : (
                        <TimerPicker
                          className={classNames({
                            ltr: direction === "ltr",
                          })}
                          value={getTimecodeFromSeconds(element.range.st, {
                            tcOffsets: element.tcOffsets,
                          })}
                          handleChange={_.noop}
                          handleBlur={_.noop}
                          handleApprove={handleTimecodeChangeApprove}
                          handleCancel={handleTimeChangeCancel}
                          step={1}
                          disabled={disabled}
                          offset={tcOffset}
                        />
                      )
                    ) : null}
                  </>
                ) : (
                  <div className="timeLabelsContainer">
                    <span
                      className={classNames("startTime", { allowTimeEdit })}
                      onClick={() => {
                        if (allowTimeEdit) {
                          setIsEditingTime("time");
                        }
                      }}
                    >
                      {TimeService.getTimeStringFromSecs(element.range.st)}
                    </span>
                    {!hideTimecode && (
                      <span
                        className={classNames("startTimeTC", {
                          allowTimeEdit,
                        })}
                        onClick={() => {
                          if (allowTimeEdit) {
                            setIsEditingTime("timecode");
                          }
                        }}
                      >
                        {featureFlags?.timecodePicker
                          ? getTimecodeFromSeconds(element.range.st, {
                              tcOffsets: element.tcOffsets,
                            })
                          : getTcString(
                              element.range.st,
                              element.tcOffsets,
                              false
                            )}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              className={"speakerTimesPlaceholder"}
              style={{ height: `${speakersTimesHeight}px` }}
            ></div>
          )}
        </div>
      </div>
      <div className={classNames("textContainer", { disabled })}>
        <div
          className="rangeText"
          onDoubleClick={handleDoubleClick}
          ref={rangeRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default RangeSpeaker;
