import { JobTCOffsets, Media } from "@sumit-platforms/types";
import TimeService from "./TimeService";
import WaveSurfer from "wavesurfer.js";
import regions from "wavesurfer.js/src/plugin/regions";
import cursor from "wavesurfer.js/src/plugin/cursor";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { Peaks } from "wavesurfer.js/types/backend";
import { freeze } from "@sumit-platforms/ui-bazar/utils";
import _ from "lodash";
// import { secondsToTimeString } from "../utils/formatters";
// import TrackingService from "./TrackingService";

const logger = console;

const WaveSurferConfig: WaveSurferParams = {
  container: "#waveform",
  progressColor: "#3c5069",
  backend: "MediaElement",
  scrollParent: true,
  hideScrollbar: false,
  autoCenter: true,
  barHeight: 1,
  responsive: true,
  barMinHeight: 0.5,
};

class MediaService {
  private static instance: MediaService;
  public media!: HTMLAudioElement | HTMLVideoElement;
  public frameRate = 25;
  public frameLength = 1 / this.frameRate;
  private mediaOffset = 0;
  public isStream = false;
  public updatingStream = false;
  public status!: "playing" | "buffering" | "ended" | null;
  public tcOffsets: JobTCOffsets = [[0, 0]];
  private loadRetries = {} as Record<string, number>;
  private maxLoadRetries = 5;

  public wavesurfer: WaveSurfer | null = null;

  public get isPlaying() {
    return this.media && !this.media.paused;
  }

  public get currentTime() {
    return this.media && this.media.currentTime;
  }

  public setOffset = (offset: number) => {
    if (!this.media) return;

    this.media.currentTime = offset + this.mediaOffset;
  };

  public playOffset = (offset?: number) => {
    if (!this.media) return;
    this.media.currentTime = this.mediaOffset + (offset || 0);
    this.play(true);
  };
  public setSubtitles = (subtitles: string) => {
    const track = document.querySelector("#track") as HTMLTrackElement;
    if (track) {
      track.src = `data:text/vtt;base64,${subtitles}`;
    }
  };

  public play = (playedWithOffset = false) => {
    try {
      if (!this.isPlaying) this.media.play();
    } catch (err) {
      logger.error(err, "play failed");
    }

    if (playedWithOffset) return;
    // TrackingService.reportEvent("media_play");
  };

  public togglePlay = (offset?: number) => {
    try {
      this.isPlaying ? this.media.pause() : this.playRelative(offset || 0);
    } catch (err) {
      logger.error(err, "togglePlay failed");
    }
  };

  public playRelative = (relativeOffset: number) => {
    if (!this.media) return;

    let offset = this.media.currentTime + relativeOffset;
    offset = Math.max(offset, 0);

    this.playOffset(offset);

    // TrackingService.reportEvent(`media_jump_${relativeOffset}`);
  };

  public pause = () => {
    if (this.media) this.media.pause();

    // TrackingService.reportEvent("media_pause");
  };

  public initializeMedia = ({
    media,
    setMedia,
    setMediaLength,
    setCurrTime,
    setIsPlaying,
    mediaLength,
    offset = 0,
    fps = 25,
    videoContainerId = "videoPlayerContainer",
    generalOffset,
    autoPlay = true,
    isStreaming = false,
    jobId,
  }: {
    media: Media;
    setMedia: (newMedia: Media) => void;
    setMediaLength: (mediaLength: number) => void;
    setCurrTime: (currTime: number, tcOffsets: JobTCOffsets) => void;
    setIsPlaying: (isPlaying: boolean) => void;
    mediaLength: number;
    offset?: number;
    fps?: number;
    videoContainerId?: string;
    generalOffset?: number;
    autoPlay?: boolean;
    isStreaming?: boolean;
    jobId: string;
  }) => {
    this.mediaOffset = generalOffset || 0;
    this.setFrameRate(fps);

    this.media = MediaService.Video(media.url || "", videoContainerId);

    this.media.onloadedmetadata = () => {
      setMediaLength(this.media.duration);
      autoPlay && this.play();
    };
    this.media.ontimeupdate = () => {
      setCurrTime(this.media.currentTime + offset, this.tcOffsets);
    };
    this.media.onplaying = () => {
      setIsPlaying(true);
    };
    this.media.onpause = () => {
      setIsPlaying(false);
    };
    this.media.onerror = async (err) => {
      console.error("Media player failed to play", err);
      const currentTime = this.currentTime;

      const canPlayType = this.media.canPlayType(media.mimetype);
      const canPlayTypeMp3 = this.media.canPlayType("audio/mp3");
      const canPlayTypeMp4 = this.media.canPlayType("video/mp4");
      if ((canPlayType || canPlayTypeMp3 || canPlayTypeMp4) && this.media.src) {
        await freeze(1500);
        this.increaseMediaLoadRetries(this.media.src);
        if (this.loadRetries[this.media.src] <= this.maxLoadRetries) {
          this.media.load();
        } else {
          //TODO : ADD ERROR HANDLING
          // Error Toast
        }
      } else {
        //TODO : ADD ERROR HANDLING
        // Error Toast
      }
      this.playOffset(currentTime);
    };
  };

  private increaseMediaLoadRetries = (key: string) => {
    if (this.loadRetries[key]) {
      this.loadRetries[key]++;
    } else {
      this.loadRetries[key] = 1;
    }
  };

  public initWaveform = (options: { peaks?: Peaks }) => {
    const waveformContainer = document.getElementById("waveform");
    if (!waveformContainer || this.wavesurfer) return;

    const videoPlayer = document.getElementById(
      "videoPlayer"
    ) as HTMLVideoElement;

    if (!videoPlayer) return;

    this.wavesurfer = WaveSurfer.create({
      ...WaveSurferConfig,
      plugins: [
        regions.create({
          regionsMinLength: 0,
        }),
        cursor.create({
          // showTime: true,
          opacity: "0.6",
          zIndex: 99,
          // formatTimeCallback: secondsToTimeString,
          // customShowTimeStyle: {
          //   backgroundColor: "#000",
          //   color: "#fff",
          //   padding: "3px",
          //   fontSize: "14px",
          // },
        }),
      ],
    });

    this.wavesurfer.load(videoPlayer, options.peaks);

    // TrackingService.reportEvent("waveform_init");
  };

  public clearWaveformRanges = () => {
    if (!this.wavesurfer) return;
    this.wavesurfer.regions.clear();
  };

  public createWaveformRanges = ({
    ranges,
    rangesWords,
    disabled,
  }: {
    ranges: { rangeIndex: number; start: number; end: number }[];
    rangesWords?: string[];
    disabled?: boolean;
  }) => {
    if (!this.wavesurfer || !this.wavesurfer.regions) return;

    this.clearWaveformRanges();

    for (const range of ranges) {
      const regionData = {
        id: range.rangeIndex.toString(),
        start: range.start,
        end: range.end,
        drag: !disabled,
        resize: !disabled,
        data: {
          startLimit:
            range.rangeIndex > 0 ? ranges[range.rangeIndex - 1].end + 0.0 : 0, // TODO change 0.0 to configurable number when using remote config
          endLimit:
            range.rangeIndex !== ranges.length - 1
              ? ranges[range.rangeIndex + 1].start - 0.0 // TODO change 0.0 to configurable number when using remote config
              : null,
        },
        attributes: {
          rangeText: "",
          rangeTimes: `${TimeService.getTimeStringFromSecs(
            range.end,
            true,
            true
          )} - ${TimeService.getTimeStringFromSecs(range.start, true, true)}`,
        },
      };
      if (rangesWords) {
        regionData.attributes.rangeText = rangesWords[range.rangeIndex];
      }
      this.wavesurfer.addRegion(regionData);
    }
  };

  public changeSpeed = (speed: number) => {
    if (!this.media) return;

    this.media.playbackRate = speed;
  };
  public getMediaOffset = () => this.mediaOffset;

  private static Video(src: string, videoContainerId: string) {
    let videoPlayer = document.getElementById(
      "videoPlayer"
    ) as HTMLVideoElement;

    if (videoPlayer) {
      videoPlayer.src = src;
      return videoPlayer;
    }
    videoPlayer = document.createElement("video");
    const track = document.createElement("track");

    track.id = "track";
    track.kind = "subtitles";
    videoPlayer.id = "videoPlayer";

    if (src != "") {
      videoPlayer.src = src;
    }
    const videoContainer = document.getElementById(videoContainerId);
    videoContainer && videoContainer.appendChild(videoPlayer);

    track.setAttribute("default", "");
    track && videoPlayer.appendChild(track);

    return videoPlayer;
  }

  public setFrameRate = (frameRate: number) => {
    this.frameRate = frameRate;
    this.frameLength = 1 / frameRate;
  };

  public setSetCurrTime = (
    setCurrTime: (currentTime: number, _tcOffsets: JobTCOffsets) => void,
    offset = 0
  ) => {
    if (!this.media?.ontimeupdate) return;
    this.media.ontimeupdate = () => {
      setCurrTime(this.media.currentTime + offset, this.tcOffsets);
    };
  };

  public static getInstance = () => {
    if (!MediaService.instance) MediaService.instance = new MediaService();

    return MediaService.instance;
  };
}

export default MediaService.getInstance();
