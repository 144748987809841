/* eslint @typescript-eslint/no-var-requires: "off" */
// line above is for ignoring no-require at line 24-27.
import _ from "lodash";

import {
  Annotation,
  Job,
  JobData,
  JobRange,
  JobTypes,
  SpeakerRange,
  SubtitlesRange,
  SubtitlesTranslationRange,
  ValidationsConfigData,
  Word,
  AnnotationRangeElement,
  CustomEditor,
  CustomElement,
  ElementType,
  MarkWordMetadata,
  SpeakerRangeElement,
  EditorFeatureFlags,
} from "@sumit-platforms/types";

import rangeValidations from "../validations/rangeValidations";
import MediaService from "./MediaService";
import { generateId } from "../utils/generateId";

import * as Diff from "diff";
import {
  Descendant,
  Editor,
  Element,
  Location,
  MergeNodeOperation,
  Node,
  Path,
  SplitNodeOperation,
  Transforms,
  Text,
  NodeEntry,
  Range,
} from "slate";
import { ReactEditor } from "slate-react";
import React from "react";
import { freeze } from "@sumit-platforms/ui-bazar/utils";

const getTempSpeakerName = (): string => {
  // const tempSpeakerName = i18n.t("unidentified_speaker");
  const tempSpeakerName = "unidentified_speaker";
  return `${tempSpeakerName}-${(Math.random() * 10000).toFixed(0)}`;
};

const getRangeWordsFromString = (
  rangeWords: Word[],
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  return updatedRangeWords;
};

const preventCut = (e: React.KeyboardEvent) => {
  // prevent cut from user
  e.preventDefault();
  e.stopPropagation();
  return;
};

const getRangeWordsFromMultilineString = (
  range: JobRange,
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const rangeWords = range.words;
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks

  let wordCount = 0;
  const lineBreaks = newInputString
    .trim()
    .split("\n")
    .slice(0, -1)
    .map((line) => {
      const lineLength = line.trim().split(" ").length;
      wordCount = wordCount + lineLength;
      return wordCount;
    });
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords,
    range
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      if (!_.isNumber(updatedRangeWords[i]?.line_ix)) continue;
      updatedRangeWords[i] = { ...updatedRangeWords[i], line_ix: lineIndex };
    }
  }

  return updatedRangeWords;
};

// const saveUserLastPosition = ({
//   jobId,
//   cursorPosition,
//   rangeIx,
//   playbackPosition,
//   scrollOffsetTop,
// }: {
//   jobId: string;
//   cursorPosition: number;
//   rangeIx: number;
//   playbackPosition: number;
//   scrollOffsetTop: number;
// }) => {
//   const newLastPosition = {
//     cursorPosition,
//     rangeIx,
//     playbackPosition,
//     scrollOffsetTop,
//   };
//   try {
//     localStorage.setItem(
//       `${jobId}/editorLastPosition`,
//       JSON.stringify(newLastPosition)
//     );
//   } catch (err) {
//     logger.error(err, "saveUserLastPosition");
//     clearLocalStorage({ preserveSettings: true });
//     saveUserLastPosition({ ...newLastPosition, jobId });
//   }
// };

const getLastPosition = (jobId: string | number) => {
  const defaultPosition = {
    cursorPosition: 0,
    rangeIx: 0,
    playbackPosition: 0,
    scrollOffsetTop: 0,
  };
  const lastPositionRaw = localStorage.getItem(`${jobId}/editorLastPosition`);
  const lastPosition = lastPositionRaw
    ? JSON.parse(lastPositionRaw)
    : defaultPosition;

  return lastPosition;
};

const calculateNewWordsTimes = (
  oldWords: Word[],
  newWords: string[],
  range?: JobRange
): Word[] => {
  const words = [];
  const wordsDiff = Diff.diffArrays(
    oldWords.map((w) => w.word),
    newWords
  );

  let wordIndex = 0;
  for (let i = 0; i < wordsDiff.length; i++) {
    const diff: any = wordsDiff[i];

    if (diff.removed) {
      if (!_.get(wordsDiff, `[${i + 1}].added`)) {
        wordIndex = wordIndex + diff.value.length;
      } else if (diff[i + 1]) {
        wordIndex = wordIndex + (diff.value.length - diff[i + 1].value.length);
      }
      continue;
    }

    for (const word of diff.value) {
      const oldWordObj =
        wordIndex > oldWords.length - 1
          ? _.last(oldWords)
          : oldWords[wordIndex];
      if (!oldWordObj) {
        console.error(
          "old word didnt found, it can harm on v3 editor. slate will be ok"
        );
        if (!range) {
          throw new Error("NOT GOOD!");
        }
      }
      const wordObj = {
        ...oldWordObj,
        id: generateId("w_"),
        speaker: oldWordObj?.speaker || null,
        word: word,
        text: word,

        start: -1,
        end: -1,
        range_ix: -1,
      };

      if (!diff.removed && !diff.added) {
        wordObj.start_time = oldWordObj?.start_time || range?.st;
        wordObj.end_time = oldWordObj?.end_time || range?.et;
        wordObj.range_ix = oldWordObj?.range_ix || -1;
        wordIndex++;
        words.push(wordObj);
      }

      if (diff.added) {
        wordObj.start_time = oldWordObj?.start_time || range?.st;
        wordObj.end_time = oldWordObj?.end_time || range?.et;
        wordObj.range_ix = oldWordObj?.range_ix || -1;
        wordIndex++;
        words.push(wordObj);
      }
    }
  }

  return words as Word[];
};

// const getSelectedWordsIndex = (
//   plainWords: string,
//   selectionStart: number,
//   selectionEnd: number
// ): { startWordIndex: number; endWordIndex: number } => {
//   const editedWords = plainWords.split(" ");
//   const lengths = editedWords.map((word) => word.length);

//   let start = selectionStart;

//   let startWordIndex = -1;
//   while (start > 0) {
//     startWordIndex++;
//     start = start - lengths[startWordIndex] - 1;
//   }
//   if (start === 0) startWordIndex++;

//   let end = selectionEnd;

//   let endWordIndex = -1;
//   while (end > 0) {
//     endWordIndex++;
//     end = end - lengths[endWordIndex] - 1;
//   }
//   if (end === 0) endWordIndex++;

//   return { startWordIndex, endWordIndex };
// };

const generateRangesByTimeIntervals = (
  words: Word[],
  interval: number
): number[] => {
  const intervalRanges = [0];
  let currentInterval = interval;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.start_time <= currentInterval) {
      continue;
    }

    while (word.start_time >= currentInterval) {
      currentInterval = currentInterval + interval;
    }

    intervalRanges.push(i);
  }

  return intervalRanges;
};

const getWordsFromRanges = (ranges: JobRange[]) => {
  let undefindSpeakerCount = 1;
  const rangesWords = _.map(ranges, (r, i) => {
    const rangeSpeaker =
      r.speakerName || `Unidentified speaker ${undefindSpeakerCount}`;
    if (!r.speakerName) {
      undefindSpeakerCount++;
    }
    return _.map(r.words, (w) => ({
      ...w,
      range_ix: i,
      speaker: rangeSpeaker,
    }));
  });
  const words = _.flatten(rangesWords);
  return words;
};

const _getExportedRangeString = (
  words: Word[],
  injectSpeakerName: boolean,
  previousSpeaker: string | null
): string => {
  let currentSpeaker = previousSpeaker;
  return words
    .map((w, i) => {
      if (
        previousSpeaker &&
        injectSpeakerName &&
        w.speaker !== currentSpeaker
      ) {
        currentSpeaker = w.speaker || null;
        return `${i !== 0 ? "\r\n" : ""}${currentSpeaker}: ${w.word}`;
      } else {
        return w.word;
      }
    })
    .filter((w) => !!w && !!w.trim())
    .join(" ");
};

const reorderWordsRangeIndex = (words: Word[]): Word[] => {
  let runningIndex = 0;
  let currentIndex = words[0].range_ix;

  return words.map((word) => {
    if (word.range_ix > currentIndex) {
      currentIndex = word.range_ix;
      runningIndex++;
    }

    return { ...word, range_ix: runningIndex };
  });
};

const getJobLangKey = (type: keyof JobTypes): "input" | "output" => {
  const defaultLangKey = "output";
  const jobLangKey = defaultLangKey;
  // const jobLangKey = jobTypes[type].lang || defaultLangKey;
  return jobLangKey;
};

const getLangDirection = (lang: string[] | string): "rtl" | "ltr" => {
  const rtlLangs = ["he-IL", "iw-IL", "ar"];
  if (_.isArray(lang)) {
    return _.some(rtlLangs, (l) => lang[0].startsWith(l)) ? "rtl" : "ltr";
  } else {
    return _.some(rtlLangs, (l) => lang.startsWith(l)) ? "rtl" : "ltr";
  }
};

const getSpeakersFromWords = (words: Word[]): string[] => {
  return _.uniqBy(words, (word) => word.speaker).map(
    (word) => word.speaker || ""
  );
};

const getSplitMeetingWords = (
  words: Word[],
  wordIndex: number,
  wordCharIndex: number
): Word[] => {
  if (wordCharIndex === 0 || wordCharIndex === words[wordIndex].word.length)
    return _.clone(words);

  const updatedMeetingWords: Word[] = _.clone(words);
  const word = updatedMeetingWords[wordIndex];
  const newWord = _.clone(word);
  word.word = word.word.slice(0, wordCharIndex);
  newWord.word = newWord.word.slice(wordCharIndex, newWord.word.length);
  updatedMeetingWords.splice(wordIndex + 1, 0, newWord);

  return updatedMeetingWords;
};

const resetSubtitlesRanges = (words: Word[]) => {
  return _.map(words, (w) => ({ ...w, range_ix: 0 }));
};

const createNewSpeakerRange = ({
  words,
  st,
  et,
  speakerId = null,
  speakerName = null,
  speakerNameEdit,
  annotations = [],
  textEdit,
  timeEdit,
}: {
  words: Word[];
  six?: number;
  eix?: number;
  st?: number;
  et?: number;
  speakerId?: null | string;
  speakerName?: null | string;
  speakerNameEdit?: boolean;
  annotations?: Annotation[];
  timeEdit?: boolean;
  textEdit?: boolean;
}) => {
  const _startTime = st || words[0]?.start_time;
  const _endTime = et || words[words.length - 1]?.end_time;

  const startTime = _.isNumber(_startTime) ? _startTime : null;
  const endTime = _.isNumber(_endTime) ? _endTime : null;
  const newSpeakerRange = {
    id: generateId("r_"),
    six: 0,
    eix: 0,
    words: words,
    st: startTime,
    et: endTime,
    speakerName,
    speakerId,
    type: "speaker",
    annotations: annotations,
    time_edit: timeEdit,
    text_edit: textEdit,
  } as SpeakerRange;
  if (speakerNameEdit) {
    newSpeakerRange.speakerNameEdit = speakerNameEdit;
  }
  return newSpeakerRange;
};

const createNewAnnotation = (rangeIndex: number, annotationIndex?: number) => {
  const newAnnotation = {
    id: generateId(),
    type: "note",
    text: "",
    range_ix: rangeIndex,
    temp: true,
  } as Annotation;

  return newAnnotation;
};

const validateJobRanges = async (
  ranges: JobRange[],
  validationConfig: ValidationsConfigData
) => {
  if (_.isEmpty(ranges) || _.isEmpty(validationConfig)) {
    return ranges;
  }

  const validationTests = _.intersection(
    _.keys(validationConfig),
    _.keys(rangeValidations)
  );

  const validationResults = await Promise.all(
    _.map(validationTests, (validationTestName) => {
      if (!_.has(rangeValidations, validationTestName)) return;

      const validationTest: any = _.get(rangeValidations, validationTestName);
      const clientValidationOptions = _.get(
        validationConfig,
        validationTestName
      );

      const jobParams = {
        jobType: validationConfig.jobType,
        lang: validationConfig.lang,
        frameLength: MediaService.frameLength,
      };
      const validationTestOptions = _.isObject(clientValidationOptions)
        ? {
            ...clientValidationOptions,
            ...jobParams,
          }
        : false;

      return validationTest(ranges, validationTestOptions);
    })
  );

  return validationResults;
};

export const reIndexWords = (words: Word[], rangeIndex: number) => {
  let lineIndex = 0;

  return words.map((word, i) => {
    if (words[i + 1] && words[i + 1].line_ix === word.line_ix) {
      word.line_ix = lineIndex;
    } else {
      word.line_ix = lineIndex;
      lineIndex++;
    }
    word.range_ix = rangeIndex;
    return word;
  });
};

const addRangeIds = (ranges: JobRange[]) => {
  const _ranges = [...ranges];
  const rangesWithIDs = _ranges.map((range: JobRange) => {
    return { ...range, id: generateId("r_") };
  });
  return rangesWithIDs;
};

const jumpToWord = (
  e: React.MouseEvent<any, MouseEvent> | React.KeyboardEvent,
  plainWords: string,
  range: SubtitlesRange | SpeakerRange | SubtitlesTranslationRange
) => {
  let { selectionStart } = e.target as HTMLTextAreaElement;

  const editedWords = plainWords.split(" ");
  const lengths = editedWords.map((word) => word.length);

  let clickedWord = -1;
  while (selectionStart > 0) {
    clickedWord++;
    selectionStart = selectionStart - lengths[clickedWord] - 1;
  }
  if (selectionStart === 0) clickedWord++;

  if (range.words && range.words[clickedWord]) {
    MediaService.setOffset(range.words[clickedWord].start_time);
  }
};

const getCursorPosition = (editableDiv: HTMLDivElement) => {
  const selection = window.getSelection();
  let selectionStart = null;
  let selectionEnd = null;
  if (selection?.rangeCount) {
    const range = selection.getRangeAt(0);
    const preCaretRangeStart = range.cloneRange();
    const preCaretRangeEnd = range.cloneRange();

    preCaretRangeStart.selectNodeContents(editableDiv);
    preCaretRangeEnd.selectNodeContents(editableDiv);

    preCaretRangeStart.setEnd(range.startContainer, range.startOffset);
    preCaretRangeEnd.setEnd(range.endContainer, range.endOffset);

    selectionStart = preCaretRangeStart.toString().length;
    selectionEnd = preCaretRangeEnd.toString().length;
  }

  return {
    selectionStart,
    selectionEnd,
    textLength: editableDiv.innerText.length,
  };
};

const formatRangeWordsToString = (words: Word[]) => {
  return words.map((word) => word.word).join(" ");
};

const formatAnnotationDataToSlateAnnotation = (
  ann: Annotation,
  focusOnInit?: boolean
) => {
  const annotationRangeElement: AnnotationRangeElement = {
    type: "annotationRange" as ElementType,
    annotationType: ann.type,
    temp: ann.temp,
    annotation: ann,
    focusOnInit: !!focusOnInit,
    children: [{ text: ann.text }],
  };

  return annotationRangeElement;
};

const formatSlateChildrenFromRange = (range: SpeakerRange) => {
  const children: any[] = [];
  let currentText = "";

  range.words.forEach((word, index) => {
    const isLastWord = index === range.words.length - 1;

    if (word?.style) {
      // Insert styled text into slate children (persist style)
      // TODO: not work 100% yet, last styled word in chain should get space
      // if (currentText) {
      //   children.push({ text: currentText });
      //   currentText = "";
      // }
      //
      // const styledText = word.word + (isLastWord ? "" : " ");
      //
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-expect-error
      // children.push({ text: styledText, ...word.style });
    } else {
      // Accumulate unstyled text with spaces between words
      currentText += word.word + (isLastWord ? "" : " ");
    }
  });

  children.push({ text: currentText });

  return children;
};

const formatSpeakerRangeToSlateElement = ({
  range,
  job,
}: {
  range: SpeakerRange;
  job: Job;
}): CustomElement[] => {
  const elements = [];

  const children = formatSlateChildrenFromRange(range);
  const speakerRangeElement: SpeakerRangeElement = {
    type: "speakersRange" as ElementType,
    children,
    range,
    tcOffsets: job.tcOffsets || [],
    annotations: (range as SpeakerRange).annotations,
  };
  elements.push(speakerRangeElement);

  if ((range as SpeakerRange)?.annotations?.length > 0) {
    const annotationElements = (range as SpeakerRange).annotations.map(
      (ann, idx) => formatAnnotationDataToSlateAnnotation(ann)
    );
    elements.push(...annotationElements);
  }
  return elements;
};

const formatJobDataToEditorValue = (job: Job): Descendant[] => {
  if (!job?.data?.ranges) {
    console.error(`trying to open editor without job data!`);
    return [];
  }
  const res: Descendant[] = [];
  const ranges = (job.data as JobData).ranges;
  ranges.forEach((range) => {
    const element = formatSpeakerRangeToSlateElement({
      range: range as SpeakerRange,
      job,
    });
    res.push(...element);
  });

  return res.flat();
};

const formatEditorValueToJobData = (values: CustomElement[]) => {
  const parsedSpeakerRanges = formatEditorSpeakerRangeToJobData(values);

  return parsedSpeakerRanges;
};

const getAssociatedAnnotations = (ranges: CustomElement[]) => {
  if (!ranges.length) return [];

  const annotations: AnnotationRangeElement[] = [];
  let isNextRangeAnnotation = true;
  let i = 0;

  while (isNextRangeAnnotation) {
    if (ranges[i] && ranges[i].type === "annotationRange") {
      annotations.push(ranges[i] as AnnotationRangeElement);
      i++;
    } else {
      isNextRangeAnnotation = false;
    }
  }
  return annotations;
};

const getRangesWithAnnotationFromSlate = (speakerRanges: Descendant[]) => {
  const rangesWithAnnotations = [] as SpeakerRangeElement[];
  speakerRanges.forEach((slateNode, idx) => {
    if ((slateNode as CustomElement).type === "annotationRange") {
      return;
    } else {
      const nextRanges = speakerRanges.slice(idx + 1) as CustomElement[];
      const annotations = getAssociatedAnnotations(nextRanges);
      rangesWithAnnotations.push({
        ...slateNode,
        annotations,
      } as SpeakerRangeElement);
    }
  });
  return rangesWithAnnotations;
};

const formatEditorSpeakerRangeToJobData = (speakerRanges: Descendant[]) => {
  const rangesWithAnnotations = getRangesWithAnnotationFromSlate(speakerRanges);

  const newRanges = rangesWithAnnotations.map((slateNode, idx) => {
    const element = _.clone(slateNode) as SpeakerRangeElement;
    const plainWords = Node.string(element);
    const oldPlainWords = element.range.words
      .map((w: Word) => w.word)
      .join(" ");
    const updatedRangeWords = getRangeWordsFromMultilineString(
      element.range,
      plainWords,
      oldPlainWords,
      speakerRanges.length
    );
    const newRange: SpeakerRange = {
      ...element.range,
      st: element.range.st,
      et: element.range.et,
      speakerId: null, // ?
      speakerName: element.range.speakerName || null,
      type: "speaker",
      words: updatedRangeWords,
      annotations: formatEditorAnnotationToJobData(element.annotations),
    };
    return newRange;
  });
  return newRanges;
};

const formatEditorAnnotationToJobData = (annotationRanges: Descendant[]) => {
  if (!annotationRanges.length) return [];
  const newRanges = annotationRanges.map((slateNode) => {
    const element = _.clone(slateNode) as AnnotationRangeElement;
    const text = Node.string(element);
    const newAnnotation: Annotation = {
      text,
      type: element.annotationType,
    };
    return newAnnotation;
  });
  return newRanges;
};

const getCurrentSelectionBlockPath = (editor: CustomEditor) => {
  //! Bug here. the next will be next of last element, not of the last cursored element
  //! editor.selection is null when refreshing the page & clicking one of the speakers then tab
  if (!editor || !editor.selection) return null;
  const currentBlock = Editor.above(editor);

  const currentPath = currentBlock ? currentBlock[1] : null;
  if (!currentPath) return null;
  return currentPath;
};

// Get the index of the current selection/cursor-on range.
const getCurrentSelectionNodeIndex = (editor: CustomEditor) => {
  if (!editor || !editor.selection) return null;
  const [index] = Editor.path(editor, editor.selection);
  return index;
};

// Get the element witch selection/cursor-on that range.
const getLastCursoredElement = (editor: CustomEditor) => {
  if (!editor || !editor.selection) return null;
  const currentBlock = Editor.above(editor);
  if (!currentBlock) return null;
  return {
    element: currentBlock[0] as CustomElement,
    path: currentBlock[1][0],
  };
};

// Get the index of the element range, including annotations!!
const getCurrentIndexByElement = (
  editor: CustomEditor,
  element: CustomElement
) => {
  if (!editor || !element) return null;
  const [index] = ReactEditor.findPath(editor, element);
  return index;
};

const getElementByPath = (editor: CustomEditor, path: number[]) => {
  const element = Editor.node(editor, path);
  return element as NodeEntry<CustomElement>;
};

const getSlateBlock = (editor: CustomEditor, currentPath: number[]) => {
  const entry = Editor.previous(editor, {
    at: currentPath,
    match: (n) => Editor.isBlock(editor, n as Element),
  });
  const path = entry ? entry[1] : null;
  return { entry, path };
};

// Set focus by path or element
const focusByPathOrElement = (
  editor: CustomEditor,
  options: { path?: number[]; element?: Element }
) => {
  const { path, element } = options;
  if (!path && !element) return;

  const pathToFocus =
    path || ReactEditor.findPath(editor as ReactEditor, element as Element);

  setTimeout(() => {
    Transforms.select(editor, {
      anchor: Editor.start(editor, pathToFocus),
      focus: Editor.start(editor, pathToFocus),
    } as Location);
    ReactEditor.focus(editor as ReactEditor);
  }, 0);
};

const focusToNextSpeakerTextRange = (editor: CustomEditor): any => {
  const currentPath = getCurrentSelectionBlockPath(editor);
  if (!currentPath) return null;
  const nextPath = Path.next(currentPath);
  if (!editor.children[nextPath[0]]) {
    console.error(`Trying to focus to next speaker text range failed.`);
    return null;
  }

  focusByPathOrElement(editor, { path: nextPath });

  //TODO: comment the code below to jump the cursor to the speakers.
  // im not doing it now because the tab click afterwards is not working as expected.
  // also, when uncomment implement same logic at focusToPrevSpeakerTextRange.

  // const nextBlock = getElementByPath(editor, Path.next(currentPath));
  // if (nextBlock && nextBlock[0]?.type !== "annotationRange") {
  //   Transforms.setNodes(
  //     editor,
  //     { focusOnInit: true },
  //     { at: Path.next(currentPath) }
  //   );
  // } else {
  //   focusByPathOrElement(editor, { path: Path.next(currentPath) });
  // }
};

const focusToPrevSpeakerTextRange = (editor: CustomEditor): any => {
  const currentPath = getCurrentSelectionBlockPath(editor);
  if (!currentPath) return null;
  const prevPath = Path.previous(currentPath);
  if (!editor.children[prevPath[0]]) {
    console.error(`Trying to focus to prev speaker text range failed.`);
  }
  focusByPathOrElement(editor, { path: prevPath });
};

const updateNodeData = ({
  data,
  editor,
  element,
}: {
  element: Element;
  data: Partial<CustomElement>;
  editor: CustomEditor;
}) => {
  const path = ReactEditor.findPath(editor as ReactEditor, element);
  Transforms.setNodes(editor, data, { at: path });
};

const insertNode = (editor: CustomEditor, node: Node, path: number[]) => {
  Transforms.insertNodes(editor, node, { at: path });
};

const createSlateAnnotation = (editor: CustomEditor) => {
  const node = getLastCursoredElement(editor);
  const textLength = node && Node.string(node?.element)?.length;

  const isCursorInStart = editor.selection?.anchor.offset === 0;
  const isCursorInEnd = editor.selection?.anchor.offset === textLength;

  if (!isCursorInStart && !isCursorInEnd) editor.splitNodes({ always: true });
  const path = getCurrentSelectionBlockPath(editor);
  if (path) Transforms.setNodes(editor, { focusOnInit: false }, { at: path });

  const rangeIndex = getCurrentSelectionNodeIndex(editor);
  if (!path || _.isNull(rangeIndex)) return;
  const newAnnotation = createNewAnnotation(rangeIndex);
  const slateAnnotation = formatAnnotationDataToSlateAnnotation(
    newAnnotation,
    true
  );

  const annotationPath =
    isCursorInEnd || rangeIndex === 0 ? Path.next(path) : path;
  insertNode(editor, slateAnnotation, annotationPath);
};

const removeNode = (editor: CustomEditor, element: Element) => {
  const path = ReactEditor.findPath(editor as ReactEditor, element);
  Transforms.removeNodes(editor, { at: path });
};

// Reset slate state will cause the whole editor to be removed & re render. use it only if you know what you are doing
const expensivelyResetEditorState = (
  editor: CustomEditor,
  newSlateState: Descendant[]
) => {
  editor.children.forEach((c) => Transforms.removeNodes(editor, { at: [0] }));
  Transforms.insertNodes(editor, newSlateState, { at: [0] });
};

const getRangesCount = (editor: CustomEditor) => {
  return editor.children?.filter(
    (n) => (n as CustomElement).type !== "annotationRange"
  )?.length;
};

const getPlainWordsFromRange = (range: JobRange) => {
  const plainWords =
    range?.words
      ?.map((w) => w.word)
      .join(" ")
      .trim() || "";
  return plainWords;
};

const getFirstWordAfterRangesBreak = ({
  oldPlainWords,
  rangesCount,
  plainWords,
  newRangeWords,
  oldRange,
}: {
  oldRange: JobRange;
  newRangeWords: Word[];
  oldPlainWords: string;
  plainWords: string;
  rangesCount: number;
}) => {
  const breakIndex = oldRange.words.length - newRangeWords.length;
  const oldRangeWords = getRangeWordsFromMultilineString(
    oldRange,
    oldPlainWords,
    plainWords,
    rangesCount
  );

  const firstNewRangeWord = oldRange.words[breakIndex];
  const firstWordCopy = { ...newRangeWords[0] }; // Create a shallow copy of the first word object to modify its properties
  if (!_.isEmpty(firstWordCopy)) {
    firstWordCopy.start_time = firstNewRangeWord.start_time;
    firstWordCopy.end_time = firstNewRangeWord.end_time;
  }

  return { oldRangeWords, firstWordCopy };
};

const getNextRange = (editor: CustomEditor, path: number) => {
  let index = path;
  let range = editor.children[index] as CustomElement | null;
  if (!range) return null;

  while (range?.type === "annotationRange") {
    index++;
    range = editor.children[index] as CustomElement | null;
  }
  return range as SpeakerRangeElement | null;
};

const trimNodeText = ({
  node,
  editor,
  nodeIndex,
}: {
  node: Node;
  editor: CustomEditor;
  nodeIndex: number;
}) => {
  const originalText = Node.string(node);
  const trimmedText = originalText.trim();

  // If the original text and trimmed text are the same, no action is needed
  if (originalText === trimmedText) return;

  const trimOperations = [];

  const startTrimLength = originalText.length - originalText.trimStart().length;
  const endTrimLength = originalText.length - originalText.trimEnd().length;

  if (startTrimLength > 0) {
    const trimStartLocation = {
      anchor: { path: [nodeIndex, 0], offset: 0 },
      focus: { path: [nodeIndex, 0], offset: startTrimLength },
    };
    trimOperations.push(trimStartLocation);
  }

  // Add operation to trim the end spaces if needed
  if (endTrimLength > 0) {
    const endTrimStartIndex = originalText.length - endTrimLength;
    trimOperations.push({
      anchor: { path: [nodeIndex, 0], offset: endTrimStartIndex },
      focus: { path: [nodeIndex, 0], offset: originalText.length },
    });
  }

  // Perform the trimming operations
  trimOperations.forEach(({ anchor, focus }) => {
    Transforms.delete(editor, {
      at: { anchor, focus },
    });
  });
};

const serializeRange = ({
  editor,
  nodeIndex,
}: {
  editor: CustomEditor;
  nodeIndex: number;
}) => {
  const node = editor.children[nodeIndex];
  if (!node) return;

  trimNodeText({
    node,
    editor,
    nodeIndex,
  });
};

const onBreakRange = (operation: SplitNodeOperation, editor: CustomEditor) => {
  const currentNode = getLastCursoredElement(editor);
  if (!currentNode?.element || !("range" in currentNode.element)) return;

  const rangesCount = getRangesCount(editor);
  const firstPartNode = editor.children[currentNode.path - 1];

  serializeRange({ editor, nodeIndex: currentNode.path - 1 });
  serializeRange({ editor, nodeIndex: currentNode.path });

  const secondPartPlainWords = Node.string(currentNode.element);
  const firstPartPlainWords = Node.string(firstPartNode);

  const oldFirstPartRange = currentNode.element.range;
  const oldFirstPartWords = getPlainWordsFromRange(oldFirstPartRange);

  const nextElement = getNextRange(editor, operation.path[0] + 2); // the element after the new range break
  const secondPartRangePath = Path.next(operation.path);

  const secondPartRangeWords = getRangeWordsFromMultilineString(
    oldFirstPartRange,
    secondPartPlainWords,
    oldFirstPartWords,
    rangesCount
  );

  const { oldRangeWords, firstWordCopy } = getFirstWordAfterRangesBreak({
    oldRange: oldFirstPartRange,
    newRangeWords: secondPartRangeWords,
    plainWords: oldFirstPartWords,
    oldPlainWords: firstPartPlainWords,
    rangesCount,
  });

  // We need to update the start time of the first new range word because the old words array is not updated.
  if (!_.isEmpty(firstWordCopy)) secondPartRangeWords[0] = firstWordCopy;
  const isCursorInStartOfRange = window.getSelection()?.focusOffset === 0;
  if (oldRangeWords.length === 0 && !isCursorInStartOfRange) {
    console.error("Trying to break empty range, operation prevented");
    Transforms.removeNodes(editor, { at: operation.path });
    return;
  }

  const newFirstPartRange = createNewSpeakerRange({
    words: oldRangeWords,
    speakerName: oldFirstPartRange.speakerName,
    annotations: [], //! Currently we are in slate context, means there is no annotation under the range
    timeEdit: true,
    textEdit: true,
    st: oldFirstPartRange.st,
    et: oldRangeWords[oldRangeWords.length - 1]?.end_time,
  });

  const newSecondPartRange = createNewSpeakerRange({
    words: secondPartRangeWords,
    speakerName: oldFirstPartRange.speakerName,
    annotations: [], //! Currently we are in slate context, means there is no annotation under the range
    timeEdit: true,
    textEdit: true,
    st: newFirstPartRange.et,
    et:
      secondPartRangeWords[secondPartRangeWords.length - 1]?.end_time ||
      nextElement?.range?.st ||
      newFirstPartRange.et,
  });

  Transforms.setNodes(
    editor,
    {
      range: newFirstPartRange,
      focusOnInit: false, //check if should be isCursorInStartOfRange,
    },
    { at: operation.path }
  );

  Transforms.setNodes(
    editor,
    {
      range: newSecondPartRange,
      focusOnInit: true, //check if should be !isCursorInStartOfRange, to focus on the new speakers panel
    },
    { at: secondPartRangePath }
  );

  return;
};
const onMergeRange = (
  operation: MergeNodeOperation,
  editor: CustomEditor,
  oldElements: CustomElement[]
) => {
  const currentNode = getLastCursoredElement(editor);
  const oldIndex = operation.path[0];
  const oldNode = oldElements[oldIndex];

  if (
    !currentNode?.element ||
    !("range" in currentNode.element) ||
    !("range" in oldNode)
  )
    return;

  const newRange = currentNode.element.range;
  const oldRange = oldNode.range;

  const words = [...newRange.words, ...oldRange.words];
  const newUpdatedRange = createNewSpeakerRange({
    ...newRange,
    et: oldRange.et,
    words,
    timeEdit: true,
    textEdit: true,
  });

  Transforms.setNodes(
    editor,
    { range: newUpdatedRange },
    { at: [currentNode.path] }
  );
};

//Updating the range words & times of the entry, or last selected element if not provided. Important for double click and break range timings.
const updateElementRange = ({
  entry,
  editor,
}: {
  editor: CustomEditor;
  entry?: { element: CustomElement; path: number } | null;
}) => {
  const node = entry || getLastCursoredElement(editor);
  if (!node?.element || _.isNil(node?.path)) return;
  const element = node.element as CustomElement;
  if (!("range" in element)) return;
  const { range } = element;
  const newInputString = Node.string(element);
  const oldInputString = getPlainWordsFromRange(range);
  const isEqual = _.isEqual(newInputString, oldInputString);

  if (isEqual && !range.time_edit) return;

  const rangesCount = getRangesCount(editor);
  const updateWords = getRangeWordsFromMultilineString(
    range,
    newInputString,
    oldInputString,
    rangesCount
  );

  const newRange = createNewSpeakerRange({ ...range, words: updateWords });
  Transforms.setNodes(editor, { range: newRange }, { at: [node.path] });
};

const updateNodeFocus = (
  editor: CustomEditor,
  node: {
    path: number;
    element: SpeakerRangeElement | AnnotationRangeElement;
  },
  focusOnInit: boolean
) => {
  updateNodeData({
    data: { focusOnInit },
    editor: editor,
    element: node.element,
  });
};

const handleOpenSpeakers = async (editor: CustomEditor) => {
  const node = getLastCursoredElement(editor);
  if (!node?.element) return;
  if (node.element.focusOnInit) {
    // if the element is already focused, we want to unfocus it first
    updateNodeFocus(editor, node, false);
    await freeze(0);
  }
  updateNodeData({
    data: { focusOnInit: true },
    editor: editor,
    element: node.element,
  });
  setTimeout(
    () =>
      updateNodeData({
        data: { focusOnInit: false },
        editor: editor,
        element: node.element,
      }),
    100
  );
};

// Jump to the current selected word, that won't work if there is no selection.
const jumpToSlateWord = (editor: CustomEditor) => {
  const currentNode = getLastCursoredElement(editor);
  if (!currentNode || !editor.selection) return;

  const { element, path } = currentNode;
  if (!element || !("range" in element)) return;

  let selectionStart = editor.selection.anchor.offset;

  const plainWords = getPlainWordsFromRange(element.range);
  const editedWords = plainWords.split(" ");

  const lengths = editedWords.map((word) => word.length);

  let clickedWord = -1;
  while (selectionStart > 0) {
    clickedWord++;
    selectionStart = selectionStart - lengths[clickedWord] - 1;
  }
  if (selectionStart === 0) clickedWord++;

  if (element.range.words && element.range.words[clickedWord]) {
    MediaService.setOffset(element.range.words[clickedWord].start_time);
  }
};

const findWordIndexAfterOffset = (wordsArray: Word[], offset: number) => {
  let cumulativeLength = 0;

  for (let i = 0; i < wordsArray.length; i++) {
    const wordLength = wordsArray[i].word.length;

    // Include space between words, except before the first word
    if (i > 0) {
      cumulativeLength += 1; // for the space
    }

    cumulativeLength += wordLength;

    if (cumulativeLength > offset) {
      return i;
    }
  }

  // If the offset exceeds the total number of characters, return -1 or the last index
  return -1; // or return wordsArray.length - 1;
};

const getHighlightWordsByText = ({
  search,
  path,
  node,
}: {
  search: string;
  node: SpeakerRangeElement | AnnotationRangeElement;
  path: number[];
}) => {
  const ranges: any = [];
  if (!search) return ranges;

  if (Array.isArray(node.children) && node.children.every(Text.isText)) {
    const texts = node.children.map((it) => it.text);
    const str = texts.join("");
    const length = search.length;
    let start = str.indexOf(search);
    let index = 0;
    let iterated = 0;
    while (start !== -1) {
      // Skip already iterated strings
      while (index < texts.length && start >= iterated + texts[index].length) {
        iterated = iterated + texts[index].length;
        index++;
      }
      // Find the index of array and relative position
      let offset = start - iterated;
      let remaining = length;
      while (index < texts.length && remaining > 0) {
        const currentText = texts[index];
        const currentPath = [...path, index];
        const taken = Math.min(remaining, currentText.length - offset);

        const wordIx = findWordIndexAfterOffset(
          (node as any).range?.words || [],
          offset
        );

        const subtext =
          (node as any)?.range?.words[wordIx]?.start_time ||
          (node as any)?.annotationType ||
          "";

        ranges.push({
          anchor: { path: currentPath, offset },
          focus: { path: currentPath, offset: offset + taken },
          highlightGreen: true,
          subtext,
          node,
        });
        remaining = remaining - taken;
        if (remaining > 0) {
          iterated = iterated + currentText.length;
          // Next block will be indexed from 0
          offset = 0;
          index++;
        }
      }
      // Looking for next search block
      start = str.indexOf(search, start + search.length);
    }
  }

  return ranges;
};

// Calculate the offset for highlighting the word
const calculateOffset = (words: any[], index: number): number => {
  let offset = 0;
  for (let i = 0; i < index; i++) {
    offset += words[i].word.length + 1; // +1 for space between words
  }
  return offset;
};

const getLastWordHighlight = ({
  path,
  currentTime,
  lastWordIndex,
  words,
  rangeEndTime,
}: {
  words: any[];
  currentTime: number;
  path: number[];
  lastWordIndex: number;
  rangeEndTime: number;
}): any | null => {
  if (lastWordIndex !== -1 && currentTime < rangeEndTime) {
    const lastWord = words[lastWordIndex];
    const offset = calculateOffset(words, lastWordIndex);
    return {
      anchor: { path: [...path, 0], offset },
      focus: { path: [...path, 0], offset: offset + lastWord.word.length },
      highlightLightblue: true,
    };
  }
  return null;
};

// Karaoke mode.
const getHighlightWordsByTime = ({
  currentTime,
  path,
  node,
}: {
  node: SpeakerRangeElement | AnnotationRangeElement;
  currentTime?: number | null;
  path: number[];
}) => {
  const ranges: any[] = [];
  if (!Array.isArray(node.children) || !Element.isElement(node)) return ranges;
  if (!_.isNumber(currentTime)) return ranges;
  if (!("range" in node) || !node.range) return ranges;
  if (currentTime < node.range.st || currentTime > node.range.et) return ranges;

  const { words } = node.range;

  let lastWordIndex = -1;

  for (const word of words) {
    const index: number = words.indexOf(word);
    const wordStart = word.start_time;
    const wordEnd = word.end_time;
    if (currentTime > wordStart) {
      // last word index is the index of the last word that has been active
      lastWordIndex = index;
    }

    if (currentTime >= wordStart && currentTime < wordEnd) {
      // Highlight word that is currently playing
      if (currentTime < wordEnd || index === words.length - 1) {
        const offset = calculateOffset(words, index);

        ranges.push({
          anchor: { path: [...path, 0], offset },
          focus: { path: [...path, 0], offset: offset + word.word.length },
          highlightLightblue: true,
        });
        return ranges;
      }
    }
  }

  // If no word is currently active, keep the last word highlighted.
  const lastWordHighlightRange = getLastWordHighlight({
    words,
    currentTime,
    path,
    lastWordIndex,
    rangeEndTime: node.range.et,
  });

  if (lastWordHighlightRange) {
    ranges.push(lastWordHighlightRange);
  }

  return ranges;
};

const getHighlightWords = ({
  search,
  currentTime,
  path,
  node,
}: {
  search: string;
  currentTime?: number | null;
  node: SpeakerRangeElement | AnnotationRangeElement;
  path: number[];
}) => {
  if (!search && !currentTime) return [];

  const highlightsBySearchTerm = search
    ? getHighlightWordsByText({ node, search, path })
    : [];

  const highlightsByCurrentTime = currentTime
    ? getHighlightWordsByTime({ node, currentTime, path })
    : [];

  return [...highlightsBySearchTerm, ...highlightsByCurrentTime];
};

const groupHighlightsByNode = (highlights: MarkWordMetadata[]) => {
  const grouped = new Map();

  highlights.forEach((highlight) => {
    const pathString = JSON.stringify(highlight.anchor.path); // Use path as a key
    if (!grouped.has(pathString)) {
      grouped.set(pathString, []);
    }
    grouped.get(pathString).push(highlight);
  });

  // Convert the Map to an array of arrays
  return Array.from(grouped.values()) as MarkWordMetadata[][];
};

// Replace selected word with new text
const replaceOne = ({
  editor,
  markedWord,
  newText,
  shouldUpdateRangeWords = true,
}: {
  editor: CustomEditor;
  markedWord: MarkWordMetadata | null;
  newText: string;
  shouldUpdateRangeWords?: boolean;
}) => {
  if (!editor || !markedWord) return;

  Transforms.select(editor, {
    anchor: markedWord.anchor,
    focus: markedWord.focus,
  });
  Transforms.insertText(editor, newText);
  if (shouldUpdateRangeWords) updateElementRange({ editor });
  ReactEditor.deselect(editor);
};

// Replacing all text occurrences with new text
const replaceAll = ({
  editor,
  highlights,
  replaceInput,
  findInput,
}: {
  editor: CustomEditor;
  highlights: MarkWordMetadata[];
  replaceInput: string;
  findInput: string;
}) => {
  if (!editor) return false;

  const groupedHighlights = groupHighlightsByNode(highlights);

  try {
    for (const rangeHighlights of groupedHighlights) {
      let updatedRangeHighlights = [...rangeHighlights];
      while (updatedRangeHighlights.length > 0) {
        const highlight = updatedRangeHighlights[0];
        const shouldUpdateRangeWords = updatedRangeHighlights.length === 1;
        replaceOne({
          editor,
          markedWord: highlight,
          newText: replaceInput,
          shouldUpdateRangeWords,
        });
        const newHighlights = getHighlightWordsByText({
          search: findInput,
          node: editor.children[highlight.anchor.path[0]] as CustomElement,
          path: [highlight.anchor.path[0]],
        });
        updatedRangeHighlights = newHighlights;
      }
    }
    clearClassname("highlightFocused");
    return true;
  } catch (e) {
    console.log(`Fail to replace all`, e);
    return false;
  }
};

const clearClassname = (className: string) => {
  const allElements = document.querySelectorAll(`.${className}`);

  allElements?.forEach((element) => {
    element.classList.remove("highlightFocused");
  });
};

const scrollAndHighlightWord = ({
  editor,
  word,
}: {
  editor: CustomEditor;
  word: MarkWordMetadata;
}) => {
  try {
    const element = ReactEditor.toDOMRange(editor, {
      focus: word.focus,
      anchor: word.anchor,
    });
    if (element) {
      clearClassname("highlightFocused");

      const textHtmlElement = element.endContainer
        ?.parentElement as HTMLSpanElement;

      const sameLength =
        textHtmlElement.innerText.length ===
        word.focus.offset - word.anchor.offset;

      if (textHtmlElement && sameLength) {
        textHtmlElement?.scrollIntoView({
          behavior: "instant",
          block: "center",
        });
        textHtmlElement?.classList?.add("highlightFocused");
      }
    }
  } catch (e) {
    console.error("Cannot scroll, focus or select highlight element.", e);
  }
};

const getRanges = (editor: CustomEditor) => {
  return editor.children
    .filter((child) => (child as CustomElement).type !== "annotationRange")
    .map((child) => (child as SpeakerRangeElement).range);
};

const navigateToCurrentPlayingRange = ({
  editor,
  markedWord,
  element,
  threshold = 50, // Threshold in pixels to define reasonable scroll range
  offset = 100, // Offset in pixels to keep the word comfortably in view
}: {
  editor: CustomEditor;
  element?: HTMLElement | null;
  markedWord?: MarkWordMetadata;
  threshold?: number;
  offset?: number;
}) => {
  if (!markedWord || !element) return;

  const isRange = Range.isRange(markedWord);
  if (!isRange) return;

  const domRange = ReactEditor.toDOMRange(editor, markedWord);
  const wordRect = domRange.getBoundingClientRect();

  if (!wordRect) return;

  const elementRect = element.getBoundingClientRect();
  const wordScrollFromTop = wordRect.top - elementRect.top + element.scrollTop;

  const isInView =
    wordScrollFromTop >= element.scrollTop - threshold &&
    wordScrollFromTop <= element.scrollTop + element.clientHeight + threshold;

  if (!isInView) {
    const newScrollTop = Math.max(
      wordScrollFromTop - offset,
      0 // Ensure we don't scroll to a negative value
    );

    element.scrollTo({ top: newScrollTop, behavior: "smooth" });
  }
};

export default {
  getTempSpeakerName,
  getRangeWordsFromString,
  getRangeWordsFromMultilineString,
  reorderWordsRangeIndex,
  getLangDirection,
  getJobLangKey,
  getSpeakersFromWords,
  getSplitMeetingWords,
  resetSubtitlesRanges,
  createNewSpeakerRange,
  createNewAnnotation,
  getWordsFromRanges,
  getLastPosition,
  validateJobRanges,
  reIndexWords,
  preventCut,
  addRangeIds,
  jumpToWord,
  getCursorPosition,
  formatJobDataToEditorValue,
  formatEditorValueToJobData,
  formatSpeakerRangeToSlateElement,
  formatEditorSpeakerRangeToJobData,
  formatRangeWordsToString,
  getCurrentSelectionNodeIndex,
  getLastCursoredElement,
  focusToNextSpeakerTextRange,
  focusToPrevSpeakerTextRange,
  updateNodeData,
  createSlateAnnotation,
  removeNode,
  expensivelyResetEditorState,
  getCurrentIndexByElement,
  getRangesCount,
  getRanges,
  getPlainWordsFromRange,
  getFirstWordAfterRangesBreak,
  onBreakRange,
  focusByPathOrElement,
  updateElementRange,
  onMergeRange,
  handleOpenSpeakers,
  jumpToSlateWord,
  getHighlightWordsByText,
  getHighlightWordsByTime,
  getHighlightWords,
  replaceOne,
  replaceAll,
  scrollAndHighlightWord,
  clearClassname,
  navigateToCurrentPlayingRange,
};
