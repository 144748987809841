import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from "slate-react";
import { createEditor, Path, Transforms } from "slate";
import { withHistory } from "slate-history";
import Range from "./components/Slate/Range";
import EditorService from "./services/EditorService";
import {
  CustomEditor,
  CustomElement,
  Job,
  MarkWordMetadata,
} from "@sumit-platforms/types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  currentTimeState,
  featureFlagsState,
  isDisabledState,
  karaokeState,
} from "./store/states";
import { useRecoilValue } from "recoil";
import { UpdateTcOffsetFn } from "./editor";
import Leaf from "./components/Slate/Leaf";
import { withRangeBreak } from "./interceptors/withRangeBreak";
import { withPlainTextPaste } from "./interceptors/withPlainTextPaste";
import { withRangeMerge } from "./interceptors/withRangeMerge";
import { Box } from "@mui/material";
import { FindAndReplace } from "./components/FindAndReplace/FindAndReplace";
import JobSpeakersPanel from "./components/JobSpeakers/JobSpeakersComponent";
import { EditableProps } from "slate-react/dist/components/editable";
import _ from "lodash";

export type SlateForwardedRef = {
  editor: CustomEditor;
  updateLastRangeInput: () => void;
  lastFindAndReplaceTerm: string;
};

interface SlateEditorProps {
  job: Job;
  updateTcOffset: UpdateTcOffsetFn;
  className?: string;
  isFindAndReplaceOpen: boolean;
  toggleIsFindAndReplaceOpen: () => void;
  handleReadOnlyClick: () => void;
}

const SlateEditor = (
  {
    job,
    updateTcOffset,
    className,
    isFindAndReplaceOpen,
    toggleIsFindAndReplaceOpen,
    handleReadOnlyClick,
  }: SlateEditorProps,
  ref: React.Ref<SlateForwardedRef>
) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  const isDisabled = useRecoilValue(isDisabledState);
  const time = useRecoilValue(currentTimeState); // time will update only if readonly and karaoke mode is active
  const karaokeMode = useRecoilValue(karaokeState);

  const [highlightSearchInput, setHighlightSearchInput] = useState("");
  const [editorController] = useState(() =>
    withRangeMerge(
      withPlainTextPaste(withRangeBreak(withHistory(withReact(createEditor()))))
    )
  );

  const editorRef = useRef<HTMLElement | null>(null);
  const lastFindAndReplaceTerm = useRef("");

  const onFindAndReplaceInput = useCallback(setHighlightSearchInput, [
    setHighlightSearchInput,
  ]);

  const onReplaceOne = useCallback(
    (markedWord: MarkWordMetadata | null, newText: string) =>
      EditorService.replaceOne({
        editor: editorController,
        markedWord,
        newText,
      }),

    [editorController]
  );
  const onReplaceAll = useCallback(
    (
      replaceInput: string,
      findInput: string,
      highlights: MarkWordMetadata[]
    ) => {
      return EditorService.replaceAll({
        editor: editorController,
        replaceInput,
        highlights,
        findInput,
      });
    },
    [editorController, highlightSearchInput]
  );

  const undo = () => {
    editorController.undo();
  };
  const redo = () => {
    editorController.redo();
  };

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      EditorService.focusToNextSpeakerTextRange(editorController);
    },

    [editorController]
  );

  const updateLastRangeInput = useCallback(
    (rangeIndex?: number) => {
      const entry = _.isNumber(rangeIndex)
        ? {
            element: editorController.children[rangeIndex] as CustomElement,
            path: rangeIndex,
          }
        : null;
      EditorService.updateElementRange({
        editor: editorController,
        entry,
      });
    },
    [editorController]
  );
  const onSpeakersRangeBlur = useCallback(
    (rangeIndex?: number) => {
      updateLastRangeInput(rangeIndex);
    },
    [updateLastRangeInput]
  );

  const handleBlur = useCallback(
    (rangeIndex?: number) => {
      onSpeakersRangeBlur(rangeIndex);
    },
    [onSpeakersRangeBlur]
  );
  const jumpToWord = useCallback(() => {
    EditorService.jumpToSlateWord(editorController);
  }, [editorController]);

  const handleJumpToWordKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      jumpToWord();
    },
    [jumpToWord]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      EditorService.focusToPrevSpeakerTextRange(editorController);
    },
    [editorController]
  );

  const createAnnotation = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      EditorService.createSlateAnnotation(editorController);
    },
    [editorController]
  );

  const handleOpenSpeakersComponentKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      EditorService.handleOpenSpeakers(editorController);
    },
    [editorController]
  );

  const handleFindAndReplaceKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      if (!featureFlags.findAndReplace) return;
      if (isFindAndReplaceOpen) {
        toggleIsFindAndReplaceOpen();
      } else {
        e.preventDefault();
        toggleIsFindAndReplaceOpen();
      }
    },
    [isFindAndReplaceOpen, isDisabled, toggleIsFindAndReplaceOpen, featureFlags]
  );
  const handleCloseActionsSectionKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDisabled) return;

      if (isFindAndReplaceOpen) {
        toggleIsFindAndReplaceOpen();
        return;
      }
    },
    [isDisabled, isFindAndReplaceOpen, toggleIsFindAndReplaceOpen]
  );

  const handleLockModeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      handleReadOnlyClick();
    },
    [handleReadOnlyClick]
  );

  const actionSectionElement = useMemo(() => {
    if (isFindAndReplaceOpen) {
      return (
        <FindAndReplace
          triggerDecoration={onFindAndReplaceInput}
          onReplaceOne={onReplaceOne}
          onReplaceAll={onReplaceAll}
          onBeforeUnmount={() => (lastFindAndReplaceTerm.current = "")}
          close={toggleIsFindAndReplaceOpen}
        />
      );
    }
    return null;
  }, [
    isFindAndReplaceOpen,
    onFindAndReplaceInput,
    onReplaceAll,
    onReplaceOne,
    toggleIsFindAndReplaceOpen,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      editor: editorController,
      updateLastRangeInput,
      lastFindAndReplaceTerm: lastFindAndReplaceTerm.current,
    }),
    [editorController, updateLastRangeInput, lastFindAndReplaceTerm]
  );

  useKeyboardShortcuts({
    handlers: {
      // BREAK_RANGE: handleBreakRangeKeyStroke,
      CREATE_NEW_ANNOTATION: createAnnotation,
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
      PREVENT_CUT: EditorService.preventCut,
      JUMP_TO_WORD: handleJumpToWordKeystroke,
      OPEN_SPEAKERS: handleOpenSpeakersComponentKeyStroke,
    },
    ref: editorRef.current,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useKeyboardShortcuts({
    handlers: {
      UNDO: undo,
      REDO: redo,
      FIND_AND_REPLACE: handleFindAndReplaceKeystroke,
      CLOSE_MODAL: handleCloseActionsSectionKeystroke,
      LOCK_MODE: handleLockModeKeystroke,
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  // const decorate = useCallback(
  //   ([node, path]: [SpeakerRangeElement, Path]): Range[] => {
  //     return [];
  //     if (!currentTime || !karaoke) return [];
  //     if (!Array.isArray(node.children) || !node.children.every(Text.isText)) {
  //       return [];
  //     }
  //     const currentWordsToHighlight: CustomRange[] = [];
  //     if (node.type === "speakersRange" && node.range) {
  //       const { words } = node.range;
  //       //TODO: re-set ranges after user change range. we want the new word at the element
  //       // Iterate over each word in the current node
  //       words.find((word: Word, index: number) => {
  //         const wordStart = word.start_time;
  //         const wordEnd = word.end_time;
  //
  //         // Check if the current word's time range overlaps with the current time
  //         if (currentTime >= wordStart && currentTime < wordEnd) {
  //           let offset = 0;
  //           for (let i = 0; i < index; i++) {
  //             offset += words[i].word.length + 1;
  //           }
  //
  //           // Add a range for highlighting the current word
  //           currentWordsToHighlight.push({
  //             anchor: { path: [...path, 0], offset },
  //             focus: { path: [...path, 0], offset: offset + word.word.length },
  //             karaoke: true,
  //           });
  //           return true;
  //         }
  //       });
  //     }
  //
  //     return currentWordsToHighlight;
  //   },
  //   [MediaService.currentTime, karaoke]
  // );

  const handleNavigateToPlayingRange = _.throttle(
    (markedWord: MarkWordMetadata) => {
      // Keep user in scroll. to be used in future when we will had some kind of "re-locate" button to bring back the user to the current playing range
      EditorService.navigateToCurrentPlayingRange({
        editor: editorController,
        markedWord,
        element: editorRef.current,
      });
    },
    2000
  );

  const handleDecorateByTime = useCallback(
    ([node, path]: [CustomElement, Path]) => {
      const ranges = EditorService.getHighlightWords({
        currentTime: time,
        node,
        path,
        search: highlightSearchInput,
      });

      return ranges;
    },
    [highlightSearchInput, time]
  );

  const handleDecorateByText = useCallback(
    ([node, path]: [CustomElement, Path]) => {
      const nodeHighlights = EditorService.getHighlightWordsByText({
        search: highlightSearchInput,
        node,
        path,
      });
      lastFindAndReplaceTerm.current = highlightSearchInput;
      return nodeHighlights;
    },
    [highlightSearchInput]
  );

  const getDecorate = useCallback(() => {
    const searchDecoration = !!(
      featureFlags.findAndReplace &&
      isFindAndReplaceOpen &&
      highlightSearchInput?.length &&
      lastFindAndReplaceTerm.current.trim() !== highlightSearchInput.trim()
    );

    const timeDecoration =
      karaokeMode && !searchDecoration && featureFlags.karaoke;

    if (searchDecoration) {
      return handleDecorateByText as EditableProps["decorate"];
    }

    if (timeDecoration) {
      return handleDecorateByTime as EditableProps["decorate"];
    }

    return;
  }, [
    featureFlags,
    isFindAndReplaceOpen,
    highlightSearchInput,
    karaokeMode,
    handleDecorateByText,
    handleDecorateByTime,
  ]);

  useEffect(() => {
    if (!editorController) return;
    try {
      const _editorRef = ReactEditor.toDOMNode(
        editorController,
        editorController
      );
      editorRef.current = _editorRef;

      if (_editorRef && _editorRef.scrollTop > 0) {
        _editorRef.scrollTo(0, 0);
      }
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorController]);

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Range
        attributes={props.attributes}
        children={props.children}
        element={props.element}
        updateTcOffset={updateTcOffset}
        hideTimecode={["protocol", "brief"].includes(job?.type.typeName)}
        handleBlur={handleBlur}
      />
    ),
    [handleBlur, job?.type.typeName, updateTcOffset]
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        Transforms.insertText(editorController, "\n", {});
      }
      if (event.key === "״") {
        event.preventDefault();
        Transforms.insertText(editorController, '"');
      }
      if (event.key === "`") {
        event.preventDefault();
        Transforms.insertText(editorController, "'");
      }
    },
    [editorController]
  );

  return (
    <Slate
      editor={editorController}
      initialValue={EditorService.formatJobDataToEditorValue(job)}
    >
      <JobSpeakersPanel
        disabled={isDisabled}
        job={job}
        editorRef={editorRef.current}
      />
      {actionSectionElement && (
        <Box className={"EditorActionsWrapper"}>{actionSectionElement}</Box>
      )}
      <Editable
        readOnly={isDisabled}
        className={className}
        renderLeaf={renderLeaf}
        decorate={getDecorate()}
        onBlur={() => handleBlur()}
        renderElement={renderElement}
        onKeyDown={onKeyDown}
      />
    </Slate>
  );
};

export default forwardRef(SlateEditor);
