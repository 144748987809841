import axios from "axios";
import { Project, ProjectDto } from "@sumit-platforms/types";

const ProjectService = ({ config }: { config: any }) => {
  const projectsEndpoint = config.server.host + "/" + config.server.projects;

  const getProjects = async (): Promise<Project[]> => {
    try {
      const response = await axios.get<Project[]>(`${projectsEndpoint}`);
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getClientProjects = async (idClient: number): Promise<Project[]> => {
    try {
      const response = await axios.get<Project[]>(
        `${projectsEndpoint}/client/${idClient}`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getMyJobsProjects = async (): Promise<Project[]> => {
    try {
      const response = await axios.get<Project[]>(
        `${projectsEndpoint}/getMyJobsProjects`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createNewProject = async (project: ProjectDto): Promise<Project> => {
    try {
      const response = await axios.post(projectsEndpoint, {
        project,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const remove = async (id: number): Promise<void> => {
    try {
      axios.delete(`${projectsEndpoint}/${id}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getProjects,
    getClientProjects,
    getMyJobsProjects,
    createNewProject,
    remove,
  };
};

export default ProjectService;
